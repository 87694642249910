import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: any) => state.user);
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }


  return <>{children}</>;
};

export default ProtectedRoute;
