import React, { Suspense, lazy } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader";
import ProtectedRoute from "./Routes/ProtectedRoute";
import IsAdminRoute from "./Routes/IsAdmin";
import CheckVerification from "./Routes/CheckVerification";
import LanguageTranslator from "./components/Forms/LanguageTranslator";

const CreateAccount = lazy(
  () => import("./pages/Authentication/CreateAccount")
);
const SignIn = lazy(() => import("./pages/Authentication/SignIn"));
const ResetPassword = lazy(
  () => import("./pages/Authentication/ResetPassword")
);
const ForgotPassword = lazy(
  () => import("./pages/Authentication/ForgotPassword")
);
const VerifyEmail = lazy(() => import("./pages/Authentication/VerifyEmail"));
const ResendVerificationCode = lazy(
  () => import("./pages/Authentication/ResendVerificationCode")
);
const CreateNewPassword = lazy(
  () => import("./pages/Authentication/CreateNewPassword")
);
const BVNVerification = lazy(
  () => import("./pages/Authentication/BVNVerification")
);
const ContinueRegistration = lazy(
  () => import("./pages/Authentication/ContinueRegistration")
);
const DocumentVerification = lazy(
  () => import("./pages/Authentication/DocumentVerification")
);
const CompleteDocumentVerification = lazy(
  () => import("./pages/Authentication/CompleteDocumentVerification")
);
const SetSecurityQuestion = lazy(
  () => import("./pages/Authentication/SetSecurityQuestion")
);
const UploadYourSelf = lazy(
  () => import("./pages/Authentication/UploadYourSelf")
);
const Dashboard = lazy(() => import("./pages/Dashboard"));
const MarketPlace = lazy(() => import("./pages/Dashboard/MarketPlace"));
const CreateNewProperty = lazy(
  () => import("./pages/Dashboard/Admin/CreateNewProperty")
);
const UpdateProperty = lazy(
  () => import("./pages/Dashboard/Admin/UpdateProperty")
);
const Admin = lazy(() => import("./pages/Dashboard/Admin"));
const AddBank = lazy(() => import("./pages/Dashboard/More/AddBank"));
const Referrals = lazy(() => import("./pages/Dashboard/More/Referrals"));
const Banks = lazy(() => import("./pages/Dashboard/More/Banks"));
const SingleBank = lazy(() => import("./pages/Dashboard/More/SingleBank"));
const SingleProperty = lazy(() => import("./pages/Dashboard/SingleProperty"));
const AdminSingleProperty = lazy(
  () => import("./pages/Dashboard/Admin/SingleProperty")
);
const More = lazy(() => import("./pages/Dashboard/More"));
const MyPortfolio = lazy(() => import("./pages/Dashboard/MyPortfolio"));
const MySingleInvestment = lazy(
  () => import("./pages/Dashboard/MySingleInvestment")
);
const SinglePropertyInvestment = lazy(
  () => import("./pages/Dashboard/SinglePropertyInvestment")
);
const Activities = lazy(() => import("./pages/Dashboard/Activities"));
const ChangePassword = lazy(() => import("./pages/Dashboard/ChangePassword"));
const NotFound = lazy(() => import("./pages/NotFound"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));

const App: React.FC = () => {
  // window.addEventListener("contextmenu", (e) => e.preventDefault());

  return (
    <Router>
      <ToastContainer />
      <Suspense>
        {/* <LanguageTranslator /> */}
        <Routes>
          <Route path="/" element={<CreateAccount />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/verify-email"
            element={
              <ProtectedRoute>
                <VerifyEmail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/request-verification-code"
            element={
              <ProtectedRoute>
                <ResendVerificationCode />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-new-password"
            element={
              <ProtectedRoute>
                <CreateNewPassword />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/bvn-verification"
            element={
              <ProtectedRoute>
                <BVNVerification />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/continue-registration"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <ContinueRegistration />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/document-verification"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <DocumentVerification />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/complete-document-verification"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <CompleteDocumentVerification />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/set-security-question"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <SetSecurityQuestion />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload-your-self"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <UploadYourSelf />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <Dashboard />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/referrals"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <Referrals />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/marketplace"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <MarketPlace />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <IsAdminRoute>
                    <Admin />
                  </IsAdminRoute>
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-new-property"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <IsAdminRoute>
                    <CreateNewProperty />
                  </IsAdminRoute>
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/update/property/:id"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <IsAdminRoute>
                    <UpdateProperty />
                  </IsAdminRoute>
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/property/:id"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <IsAdminRoute>
                    <AdminSingleProperty />
                  </IsAdminRoute>
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/more"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <More />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-portfolio"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <MyPortfolio />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-bank"
            element={
              <ProtectedRoute>
                <AddBank />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banks"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <Banks />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/bank/:id"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <SingleBank />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/learn-more/property/:id"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <SingleProperty />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/investment/:id"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <MySingleInvestment />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/learn-more/investment-property/:id"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <SinglePropertyInvestment />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/activities"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <Activities />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <CheckVerification>
                  <ChangePassword />
                </CheckVerification>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
