import {
  LOAD_PROPERTY_REQUEST,
  LOAD_PROPERTY_SUCCESS,
  LOAD_PROPERTY_FAIL,
  CLEAR_ERRORS,
} from "../constants/property";
import { LOGOUT_SUCCESS } from "../constants/user";

export const propertiesReducer = (
  state = { properties: [], total: 0 },
  action: any
) => {
  switch (action.type) {
    case LOAD_PROPERTY_REQUEST:
      return {
        loading: true,
        properties: [],
        total: 0,
        totalUsers: 0,
      };
    case LOAD_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        properties: action.payload.properties,
        total: action.payload.total,
        status: action.payload.status,
        message: action.payload.status,
      };
    case LOGOUT_SUCCESS:
      return {
        loading: false,
        properties: null,
        total: 0,
      };
    case LOAD_PROPERTY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
