export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_FAIL = "CREATE_PROPERTY_FAIL";

export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_FAIL = "UPDATE_PROPERTY_FAIL";

export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_FAIL = "DELETE_PROPERTY_FAIL";

export const LOAD_PROPERTY_REQUEST = "LOAD_PROPERTY_REQUEST";
export const LOAD_PROPERTY_SUCCESS = "LOAD_PROPERTY_SUCCESS";
export const LOAD_PROPERTY_FAIL = "LOAD_PROPERTY_FAIL";

export const GET_SINGLE_PROPERTY_REQUEST = "GET_SINGLE_PROPERTY_REQUEST";
export const GET_SINGLE_PROPERTY_SUCCESS = "GET_SINGLE_PROPERTY_SUCCESS";
export const GET_SINGLE_PROPERTY_FAIL = "GET_SINGLE_PROPERTY_FAIL";


export const CLEAR_ERRORS = "CLEAR_ERRORS";
