import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface CheckVerificationRouteProps {
  children: any;
}

const CheckVerification: React.FC<CheckVerificationRouteProps> = ({
  children,
}) => {
  const { verified } = useSelector((state: any) => state.currentUser.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!verified) {
      navigate("/verify-email");
    }
  }, [verified, navigate]);

  return children;
};

export default CheckVerification;
