import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface IsAdminRouteProps {
  children: any;
}

const IsAdminRoute: React.FC<IsAdminRouteProps> = ({ children }) => {
  const { user } = useSelector((state: any) => state.currentUser.user);
  const { isAuthenticated } = useSelector((state: any) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && !user.role.includes("admin")) {
      navigate("/");
    }
  }, [user, navigate, isAuthenticated]);

  return children;
};

export default IsAdminRoute;
