import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";


import {
  completeRegistrationReducer,
  currentUserReducer,
  forgotPasswordReducer,
  updateProfilePhotoReducer,
  userReducer,
  verifyEmailReducer,
} from "./reducers/user";

import { propertiesReducer } from "./reducers/property";
import { statDetailsReducer, propertyReducer } from "./reducers/admin";

const reducer: any = combineReducers({
  user: userReducer,
  currentUser: currentUserReducer,
  updateProfile: updateProfilePhotoReducer,
  property: propertyReducer,
  properties: propertiesReducer,
  statDetails: statDetailsReducer,
  forgotPassword: forgotPasswordReducer,
  verifyEmailReducer: verifyEmailReducer,
  completeRegistrationReducer: completeRegistrationReducer,
});

const middleware = [thunk];
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer: any = persistReducer(persistConfig, reducer);

const store: any = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

export { store, persistor };
