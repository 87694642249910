import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CLEAR_ERRORS,
  VERIFY_EMAIL_REQUEST,
  RESEND_VERIFICATION_CODE_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  RESEND_VERIFICATION_CODE_SUCCESS,
  VERIFY_EMAIL_FAIL,
  RESEND_VERIFICATION_CODE_FAIL,
  COMPLETE_REGISTRATION_REQUEST,
  COMPLETE_REGISTRATION_SUCCESS,
  COMPLETE_REGISTRATION_FAIL,
  UPDATE_PROFILE_PHOTO_REQUEST,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_FAIL,
} from "../constants/user";

export const userReducer = (state = { user: {} }, action: any) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case REGISTER_USER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      };
    case LOGIN_SUCCESS:
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case LOGOUT_SUCCESS:
      return {
        loading: false,
        user: null,
        isAuthenticated: false,
      };
    case LOGIN_FAIL:
    case REGISTER_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const currentUserReducer = (
  state = { user: { user: {} } },
  action: any
) => {
  switch (action.type) {
    case LOAD_USER_REQUEST:
      return {
        loading: true,
        user: { user: {} },
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case LOAD_USER_FAIL:
      return {
        ...state,
        loading: false,
        user: { user: {} },
        error: action.payload,
      };

    case LOGOUT_SUCCESS:
      return {
        loading: false,
        user: { user: {} },
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const verifyEmailReducer = (state = {}, action: any) => {
  switch (action.type) {
    case VERIFY_EMAIL_REQUEST:
    case RESEND_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERIFY_EMAIL_SUCCESS:
    case RESEND_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case VERIFY_EMAIL_FAIL:
    case RESEND_VERIFICATION_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const completeRegistrationReducer = (state = {}, action: any) => {
  switch (action.type) {
    case COMPLETE_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case COMPLETE_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        message: action.payload,
      };
    case COMPLETE_REGISTRATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const updateProfilePhotoReducer = (state = {}, action: any) => {
  switch (action.type) {
    case UPDATE_PROFILE_PHOTO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case UPDATE_PROFILE_PHOTO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action: any) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
