import {
  CREATE_PROPERTY_REQUEST,
  CREATE_PROPERTY_SUCCESS,
  CREATE_PROPERTY_FAIL,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAIL,
  CLEAR_ERRORS,
} from "../constants/property";
import { LOGOUT_SUCCESS } from "../constants/user";
import {
  LOAD_STAT_DETAILS_REQUEST,
  LOAD_STAT_DETAILS_SUCCESS,
  LOAD_STAT_DETAILS_FAIL,
} from "../constants/admin";

export const propertyReducer = (state = { property: {} }, action: any) => {
  switch (action.type) {
    case UPDATE_PROPERTY_REQUEST:
    case CREATE_PROPERTY_REQUEST:
      return {
        creatingProperty: true,
      };
    case UPDATE_PROPERTY_SUCCESS:
    case CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        creatingProperty: false,
        property: action.payload,
      };
    case UPDATE_PROPERTY_FAIL:
    case CREATE_PROPERTY_FAIL:
      return {
        ...state,
        creatingProperty: false,
        error: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        creatingProperty: false,
        property: null,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const statDetailsReducer = (
  state = {
    totalProperties: 0,
    total: 0,
    totalUsers: 0,
    totalSold: 0,
    totalSlots: 0,
  },
  action: any
) => {
  switch (action.type) {
    case LOAD_STAT_DETAILS_REQUEST:
      return {
        loading: true,
        totalProperties: 0,
        total: 0,
        totalUsers: 0,
        totalSold: 0,
        totalSlots: 0,
      };
    case LOAD_STAT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalProperties: action.payload.totalProperties,
        totalUsers: action.payload.totalUsers,
        totalSold: action.payload.totalSold,
        totalSlots: action.payload.totalSlots,
        status: action.payload.status,
        message: action.payload.status,
      };
    case LOGOUT_SUCCESS:
      return {
        loading: false,
        totalProperties: 0,
        total: 0,
        totalUsers: 0,
        totalSold: 0,
        totalSlots: 0,
      };
    case LOAD_STAT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
